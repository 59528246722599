import {
  BusinessContextProviderLogger,
  ExistingContextFetchingLogParams,
  NewContextFetchingLogParams,
} from 'frontend-container/shared/businessContext/provider/logger/logger';
import { logger } from 'frontend-container/utils/logger/logger';

export class BusinessContextProviderKibanaLogger
  implements BusinessContextProviderLogger
{
  logNewContextFetchRequirement(params: NewContextFetchingLogParams): void {
    this.log('Context data fetching is required', params);
  }

  logKnownContext(params: ExistingContextFetchingLogParams): void {
    const topic = `Whole context is already in the ${
      params.cacheReadResponse ? 'cache' : 'memory'
    }`;
    this.log(topic, params);
  }

  logCacheClear(): void {
    this.log('Cache cleared');
  }

  private log(topic: string, content?: object): void {
    logger?.verbose(
      {
        feLogKey: topic,
        message: JSON.stringify(content),
      },
      {
        keyForOverriddenLogLevel: 'FEBusinessContextProvider',
      }
    );
  }
}
