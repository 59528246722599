import { CSSProperties, useMemo } from 'react';
import { ContextDropdownDescription } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/ContextDropdownDescription';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { CSS_VAR_MENU_BAR_COLOR } from 'frontend-container/components/Menu/constants';
import { isMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { getCssVariable } from 'frontend-container/components/Menu/utils/getCssVariable';

import { isDarkBackground } from '@ac/library-utils/dist/utils';
import { AlignItems, Color, Size, TextColor } from '@ac/web-components';

import { Context } from '../../context';

import './ContextDropdown.scss';

interface Props {
  id?: string;
  selectedContext?: Context;
  businessDate?: string;
  dateFormat?: string;
  propertyTime?: string;
  color?: string | Color | TextColor;
  isListVisible?: boolean;
  onClick?: () => void;
  menuV2?: boolean;
  currentButtonOption?: ButtonContextOption;
  toShowOnlyIconInDropDown?: boolean;
}

export const ContextDropdown = ({
  id = 'context-dropdown-button',
  selectedContext,
  businessDate,
  dateFormat,
  propertyTime,
  color = getCssVariable(CSS_VAR_MENU_BAR_COLOR),
  isListVisible,
  onClick,
  currentButtonOption,
  toShowOnlyIconInDropDown = false,
}: Props): JSX.Element => {
  const textColor = useMemo(() => {
    return isDarkBackground(color) ? TextColor.white : TextColor.black;
  }, [color]);

  const iconColor = textColor === TextColor.white ? Color.white : Color.black;

  const classes = [
    'context-dropdown',
    isListVisible ? 'context-dropdown-active' : '',
    isMenuV2Enabled() ? 'context-dropdown-menu-v2' : '',
  ]
    .filter(Boolean)
    .join(' ');

  const code = selectedContext?.code ?? '';

  const isMenuV2EnabledCached = isMenuV2Enabled();

  if (isMenuV2EnabledCached) {
    return (
      <button
        id={id}
        className={classes}
        style={
          {
            '--context-color': color,
          } as CSSProperties
        }
        onClick={onClick}
      >
        <ac-flex
          class="context-dropdown-content context-dropdown-min-height ac-gap-sm"
          alignItems={AlignItems.center}
        >
          <ac-icon
            icon={currentButtonOption?.icon}
            color={iconColor}
            size={Size.lg}
          />

          {!toShowOnlyIconInDropDown && (
            <ContextDropdownDescription
              businessDate={businessDate}
              dateFormat={dateFormat}
              propertyTime={propertyTime}
              textColor={textColor}
              code={code}
            />
          )}
        </ac-flex>
      </button>
    );
  }

  return (
    <button
      id={id}
      className={classes}
      style={
        {
          '--context-color': color,
        } as CSSProperties
      }
      onClick={onClick}
    >
      <ac-flex
        class="context-dropdown-content ac-gap-sm"
        alignItems={AlignItems.center}
      >
        {selectedContext && (
          <ac-icon
            icon={selectedContext.icon}
            color={iconColor}
            size={Size.lg}
          />
        )}
        <ContextDropdownDescription
          businessDate={businessDate}
          dateFormat={dateFormat}
          propertyTime={propertyTime}
          textColor={textColor}
          code={code}
        />
      </ac-flex>
    </button>
  );
};
