import { isDefined } from '@ac/library-utils/dist/utils';
import { ButtonPattern, ButtonSize, IconName, Size } from '@ac/web-components';

import './Breadcrumbs.scss';

interface Props {
  id?: string;
  content?: string;
  showIcon?: boolean;
  iconSize?: Size.xs | Size.sm;
  onMouseEnter?: ((event: MouseEvent) => void) | undefined;
  onMouseLeave?: ((event: MouseEvent) => void) | undefined;
}

export const BreadcrumbsButton = ({
  content,
  id,
  onMouseEnter,
  onMouseLeave,
  showIcon = true,
  iconSize = Size.sm,
}: Props): JSX.Element | null => {
  if (!isDefined(content) || content?.length === 0) {
    return null;
  }

  return (
    <>
      {showIcon && (
        <ac-icon
          icon={IconName.actionRight}
          size={iconSize}
          class="breadcrumbs-divider"
        />
      )}
      <ac-button
        pattern={ButtonPattern.menuTrigger}
        size={ButtonSize.auto}
        id={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        class="breadcrumbs-button"
      >
        <ac-button-content text={content} />
      </ac-button>
    </>
  );
};
