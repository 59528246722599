import { FC } from 'react';
import { isMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';

import {
  ButtonPattern,
  ButtonSize,
  ButtonTheme,
  Color,
  IconName,
} from '@ac/web-components';

import './ButtonTrigger.scss';

interface Props {
  buttonIcon: IconName;
  statusInactiveIcon: IconName;
  inactiveColor: Color.alert | Color.gray;
  active: boolean;
  additionalClass?: string;
  triggerId?: string;
}

export const ButtonTrigger: FC<Props> = ({
  buttonIcon,
  statusInactiveIcon,
  inactiveColor,
  additionalClass,
  active = false,
  triggerId,
}) => {
  const icon = active ? IconName.check : statusInactiveIcon;
  const wrapperClass = active
    ? 'status-wrapper-active'
    : inactiveColor === Color.alert
    ? 'status-wrapper-alert'
    : 'status-wrapper-gray';

  return (
    <ac-button
      id={triggerId}
      pattern={
        isMenuV2Enabled() ? ButtonPattern.menuTrigger : ButtonPattern.tertiary
      }
      theme={ButtonTheme.light}
      class={`menu-infotip-status-wrapper ${additionalClass || ''}`}
      size={ButtonSize.lg}
    >
      <ac-flex class="status-icon-wrapper" dynamicClass={wrapperClass}>
        <ac-icon class="status-icon" icon={icon} color={Color.white} />
      </ac-flex>
      <ac-icon icon={buttonIcon} color={Color.white} />
    </ac-button>
  );
};
