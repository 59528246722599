import { useState } from 'react';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';
import { GenericList } from 'frontend-container/components/Menu/components/Context/components/GenericList';
import { ContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { isMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';

import {
  AcSelectOption,
  AcSelectValue,
  AlignItems,
  Color,
  FlexDirection,
  TextColor,
} from '@ac/web-components';

import { ContextDropdown } from './components/ContextDropdown';
import { ContextHeader } from './components/ContextHeader';
import { Context } from './context';

interface Props {
  selectedContext: Context | undefined;
  onSelect: (
    selected: Context | Array<AcSelectOption<AcSelectValue>>,
    discardUnsavedChanges?: boolean,
    tabId?: string
  ) => void;
  useDateTime: DateTimeHook;
  dateLabel: string;
  timeLabel: string;
  color?: string | Color | TextColor;
  thresholdToShowTabs?: number;
}

export const ContextComponent = ({
  selectedContext,
  onSelect,
  useDateTime,
  dateLabel,
  timeLabel,
  color,
  thresholdToShowTabs = 1,
}: Props): JSX.Element => {
  const [isListVisible, setIsListVisible] = useState<boolean>(false);

  const { allContextLists, tabsOptions, currentButtonOption } =
    useContextsContext();

  const [activeTab, setActiveTab] = useState(
    currentButtonOption?.id || ContextOption.property
  );

  const { date, dateFormat, time } = useDateTime();

  const handleSetActiveTab = (newActiveTab: string): void => {
    setActiveTab(newActiveTab);
  };

  const openList = (): void => setIsListVisible(true);

  const closeList = (): void => setIsListVisible(false);

  const handleSelect = (context: AcSelectOption[] | Context): void => {
    onSelect(context, false, activeTab || '');
    closeList();
  };

  const isMenuV2EnabledCached = isMenuV2Enabled();

  const showTabs =
    (tabsOptions && tabsOptions?.length > thresholdToShowTabs) || false;

  return (
    <ac-flex
      id="context-select-wrapper"
      class={isMenuV2EnabledCached ? '' : 'ac-spacing-right-s'}
      direction={FlexDirection.row}
      alignItems={AlignItems.center}
    >
      <ac-click-outside onClickOutsideCallback={closeList}>
        <ContextDropdown
          selectedContext={selectedContext}
          businessDate={date}
          dateFormat={dateFormat}
          propertyTime={time}
          color={color}
          isListVisible={isListVisible}
          onClick={openList}
          currentButtonOption={currentButtonOption}
          toShowOnlyIconInDropDown={
            currentButtonOption &&
            currentButtonOption.id === ContextOption.configuration
              ? true
              : false
          }
        />
        <GenericList
          list={allContextLists.get(activeTab)}
          selectedContext={selectedContext}
          isVisible={isListVisible}
          onSelect={handleSelect}
          onClose={closeList}
          buttonSelectOptions={tabsOptions}
        >
          {showTabs && (
            <ContextHeader
              selectedContext={selectedContext}
              date={date}
              dateLabel={dateLabel}
              time={time}
              timeLabel={timeLabel}
              buttonSelectOptions={tabsOptions}
              setActiveTab={handleSetActiveTab}
              activeTab={activeTab}
            />
          )}
        </GenericList>
      </ac-click-outside>
    </ac-flex>
  );
};
