import { useTranslation } from 'react-i18next';
import { mapMaintenanceDataToJsonContentString } from 'frontend-container/components/panels/maintenancePanel/features/generateReport/mapMaintenanceDataToJsonContent';
import { useMaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';

import { DateUtils } from '@ac/library-utils/dist/utils';
import { useContainerToast } from '@ac/react-infrastructure';

type CommandResult = () => void;

export const useCopyToClipboard = (): CommandResult => {
  const {
    state: { environment, microservicesVersions, contextReport },
  } = useMaintenancePanelContext();
  const { t } = useTranslation();
  const { showSuccess, showError } = useContainerToast();

  return async () => {
    if (!environment || !microservicesVersions) {
      return showError(t('MAINTENANCE_PANEL.COPY_TO_CLIPBOARD_FAILED'));
    }

    const content = mapMaintenanceDataToJsonContentString({
      environment,
      microservicesVersions,
      contextReport,
      dateTime: DateUtils.serialization.format(
        new Date(),
        'DD-MM-YYYY h:m:s a'
      ),
    });

    await navigator.clipboard.writeText(content);

    showSuccess(t('MAINTENANCE_PANEL.COPY_TO_CLIPBOARD_SUCCESS'));
  };
};
