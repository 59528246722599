import { FC } from 'react';
import { isMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';

import { getInitials } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { ButtonPattern, SystemUserTheme } from '@ac/web-components';

import './UserTrigger.scss';

interface Props {
  firstName?: string;
  lastName?: string;
}

export const UserTrigger: FC<Props> = ({ firstName, lastName }: Props) => {
  const initials = getInitials(
    firstName ?? LoginService.authData()?.userType ?? '',
    lastName ?? LoginService.authData()?.userType ?? ''
  );

  return !isMenuV2Enabled() ? (
    <ac-system-user theme={SystemUserTheme.light} readonly={false}>
      {initials}
    </ac-system-user>
  ) : (
    <ac-button pattern={ButtonPattern.menu}>
      <ac-button-content text={initials} />
    </ac-button>
  );
};
