import { ConfirmationModalPresenterContextProvider } from 'frontend-container/components/modals/confirmationModal/presenter/Provider';
import { determineMaintenancePanelMode } from 'frontend-container/components/panels/maintenancePanel/commands/determinePanelMode';
import { BusinessContextSectionPresenterContextProvider } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/presenter/Provider';
import { DesignSystemSectionPresenterContextProvider } from 'frontend-container/components/panels/maintenancePanel/features/designSystem/presenter/Provider';
import { EnvironmentDetailsSectionPresenterContextProvider } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/presenter/Provider';
import { FeatureTogglesSectionPresenterContextProvider } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/presenter/Provider';
import { MicroservicesVersionsSectionPresenterContextProvider } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/presenter/Provider';
import { getMaintenancePanelSectionsSchema } from 'frontend-container/components/panels/maintenancePanel/features/sectionsSchema';
import { MaintenancePanelFeatureTogglesContextProvider } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/Provider';
import { MaintenancePanelContextProvider } from 'frontend-container/components/panels/maintenancePanel/store/Provider';

import { Config } from '@ac/library-utils/dist/declarations/config';
import {
  createProvider,
  PropsWithChildren,
  ProviderComposer,
} from '@ac/react-infrastructure';

interface Props {
  config: Config;
}

export const MaintenancePanelProviders = (
  props: PropsWithChildren<Props>
): JSX.Element => {
  const mode = determineMaintenancePanelMode(props.config);
  const maintenancePanelSectionsSchema =
    getMaintenancePanelSectionsSchema(mode);

  const providers = [
    createProvider({ component: ConfirmationModalPresenterContextProvider }),
    createProvider({
      component: MaintenancePanelContextProvider,
      props: {
        mode,
      },
    }),
    createProvider({
      component: MaintenancePanelFeatureTogglesContextProvider,
    }),
    createProvider({
      component: EnvironmentDetailsSectionPresenterContextProvider,
      props: {
        initialAppearance:
          maintenancePanelSectionsSchema.environmentDetails.initialAppearance,
      },
    }),
    createProvider({
      component: MicroservicesVersionsSectionPresenterContextProvider,
      props: {
        initialAppearance:
          maintenancePanelSectionsSchema.microservicesVersions
            .initialAppearance,
      },
    }),
    createProvider({
      component: BusinessContextSectionPresenterContextProvider,
      props: {
        initialAppearance:
          maintenancePanelSectionsSchema.businessContext.initialAppearance,
      },
    }),
    createProvider({
      component: DesignSystemSectionPresenterContextProvider,
      props: {
        initialAppearance:
          maintenancePanelSectionsSchema.designSystem.initialAppearance,
      },
    }),
    createProvider({
      component: FeatureTogglesSectionPresenterContextProvider,
      props: {
        initialAppearance:
          maintenancePanelSectionsSchema.featureToggles.initialAppearance,
      },
    }),
  ];

  return (
    <ProviderComposer providers={providers}>{props.children}</ProviderComposer>
  );
};
