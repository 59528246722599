import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  propertyPermissionKeys,
  Setting,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const reservationPermissions = propertyPermissionKeys.reservations;
const inventoryPermissions = propertyPermissionKeys.inventory;

export const reservationsMenu: MenuElement = {
  translation: 'MENU.RESERVATIONS.TITLE',
  icon: IconName.reservations,
  id: 'menu-reservations',
  items: [
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations`,
      aliases: [
        acConfig.newFrontendUrls.reservations,
        `${acConfig.newFrontendUrls.individualReservations}/dashboard`,
        `${acConfig.newFrontendUrls.individualReservations}/reservation/new`,
        `${acConfig.newFrontendUrls.individualReservations}/reservation`,
      ],
      code: 'ReservationDashboard',
      translation: 'MENU.RESERVATIONS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [reservationPermissions.dashboard.viewDashboard],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+v',
      keyboardShortcutMac: '⌥+⌘+v',
      keyCode: 86,
    },
    {
      link: `${acConfig.newFrontendUrls.reservations}/availability/search`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/availability/search/room-types`,
        `${acConfig.newFrontendUrls.reservations}/availability/search/rate-plans`,
        acConfig.newFrontendUrls.availability,
      ],
      permissionsConfiguration: {
        permissions: [reservationPermissions.reservation.manageReservation],
        permissionsConjunction: PermissionsConjunction.And,
      },
      translation: 'MENU.RESERVATIONS.ITEMS.AVAILABILITY',
      keyboardShortcutWindows: 'alt+ctrl+q',
      keyboardShortcutMac: '⌥+⌘+q',
      keyCode: 81,
    },
    {
      link: `${acConfig.newFrontendUrls.reports}/availability/detailed`,
      code: 'RoomTypeAvailability',
      translation: 'MENU.RESERVATIONS.ITEMS.ROOM_TYPE_AVAILABILITY',
      permissionsConfiguration: {
        permissions: [reservationPermissions.reservation.viewReservation],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+x',
      keyboardShortcutMac: '⌥+⌘+x',
      keyCode: 88,
    },
    {
      link: `${acConfig.newFrontendUrls.rateManager}/inventory-balance-dashboard`,
      featureToggles: [FeatureToggleName.IntegrationIHG],
      translation: 'MENU.RATES.ITEMS.INVENTORY_BALANCE_DASHBOARD',
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/reservation`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations/new`,
        `${acConfig.newFrontendUrls.itinerary}/itinerary/new`,
        `${acConfig.newFrontendUrls.itinerary}/itinerary`,
      ],
      translation: 'MENU.RESERVATIONS.ITEMS.NEW',
      permissionsConfiguration: {
        permissions: [reservationPermissions.reservation.manageReservation],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.inventory}/items`,
      aliases: [acConfig.newFrontendUrls.inventory],
      code: 'ItemInventoryDashboard',
      translation: 'MENU.RESERVATIONS.ITEMS.INVENTORY_ITEMS',
      settings: [Setting.InventoryItems],
      permissionsConfiguration: {
        permissions: [inventoryPermissions.viewItemInventoryDashboard],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
