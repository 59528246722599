import { MouseEventHandler } from 'react';
import { ExternalMenuLink } from 'frontend-container/components/Menu/components/Item/SubItem/components/ExternalMenuLink';
import { InternalMenuLink } from 'frontend-container/components/Menu/components/Item/SubItem/components/InternalMenuLink';
import { MenuElementItem } from 'frontend-container/components/Menu/types';

import { FlexGap } from '@ac/web-components';

import './SubItem.scss';

interface Props {
  item: MenuElementItem;
  active: boolean;
  onClick: (id: string) => void;
  onMouseOver?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  showActionArrow?: boolean;
  id?: string;
  spacing?: FlexGap;
}

export const SubItem = ({
  item,
  active,
  onClick,
  id,
  onMouseOver,
  showActionArrow,
  onMouseLeave,
  spacing,
}: Props): JSX.Element => {
  const wrapperClassNames = [
    'menu-subitem',
    active ? 'subitem-selected' : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  return item.isExternal ? (
    <ExternalMenuLink wrapperClasses={wrapperClassNames} item={item} />
  ) : (
    <InternalMenuLink
      wrapperClasses={wrapperClassNames}
      item={item}
      active={active}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      showActionArrow={showActionArrow}
      id={id}
      spacing={spacing}
    />
  );
};
