import { MenuElement } from 'frontend-container/components/Menu/types';

import { FeatureToggleName, GolfCustomerSettingsKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const golfMenu: MenuElement = {
  translation: 'MENU.GOLF.TITLE',
  icon: IconName.golf,
  id: 'menu-golf',
  settings: [GolfCustomerSettingsKeys.GolfReservations],
  items: [
    {
      link: `${acConfig.newFrontendUrls.golf}/reservations/dashboard`,
      translation: 'MENU.GOLF.ITEMS.DASHBOARD',
      featureToggles: [FeatureToggleName.GolfDashboard],
      aliases: [`${acConfig.newFrontendUrls.golf}/reservations/`],
    },
    {
      link: `${acConfig.newFrontendUrls.golf}/reservations/planner`,
      translation: 'MENU.GOLF.ITEMS.PLANNER',
      featureToggles: [FeatureToggleName.GolfPlanner],
    },
    {
      link: `${acConfig.newFrontendUrls.profiles}/prestep/golf`,
      translation: 'MENU.GOLF.ITEMS.CREATE_RESERVATION',
      featureToggles: [
        FeatureToggleName.GolfReservationsModule,
        FeatureToggleName.GolfCreateReservation,
      ],
      aliases: [
        `${acConfig.newFrontendUrls.golf}/reservation/new`,
        `${acConfig.newFrontendUrls.golf}/reservations/new`,
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.golf}/configuration/course/dashboard`,
      translation: 'MENU.GOLF.ITEMS.COURSE_MANAGER',
      aliases: [`${acConfig.newFrontendUrls.golf}/configuration/`],
    },
  ],
  featureToggles: [FeatureToggleName.GolfReservationsModule],
};
