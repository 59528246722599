import { AllowedContextIds } from 'frontend-container/components/Menu/components/ConfigurationContext/types';
import { Context } from 'frontend-container/components/Menu/components/Context/context';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/configuration';

import { isDefined } from '@ac/library-utils/dist/utils';
import {
  BadgeTheme,
  ColorsPaletteHEX,
  CustomOption,
  CustomOptionAllowedComponents,
  GroupOptions,
} from '@ac/web-components';

export const mapContextsToOptions = (
  contextList: Context[]
): Array<{ name: string; value: string }> =>
  contextList.map((context) => {
    const regionCode = context?.regionCode ?? '';

    const customOptionsArray: CustomOption[] = [
      {
        component: CustomOptionAllowedComponents.text,
        text: regionCode
          ? [context.code, context.name].filter(isDefined).join(' - ')
          : [context.code, context.name].filter(isDefined).join(' '),
      },
    ];

    if (regionCode) {
      customOptionsArray.push({
        component: CustomOptionAllowedComponents.badge,
        text: `${context?.regionCode?.toUpperCase() ?? ''}`,
        colorBadge: ColorsPaletteHEX.blue100,
        themeBadge: BadgeTheme.informationalTheme,
      });
    }

    return {
      name: [context.code, context.name].filter(isDefined).join(' - '),
      value: context.id,
      customOptions: customOptionsArray,
    };
  });

export const mapMenuItemsToOptions = (
  menuItems: ButtonContextOption | undefined,
  selected: Context | undefined
): Array<{
  name: string;
  value: string;
  optionsControlGroup: GroupOptions;
}> => {
  const configurationMenuLink = configurationMenu.items[2].link;

  return (
    menuItems?.subMenuElements?.map((item) => {
      const code = selected?.code ?? '';

      const customOptionsArray: CustomOption[] = [
        {
          component: CustomOptionAllowedComponents.text,
          text: item.translation,
        },
      ];
      if (code && item.link === configurationMenuLink) {
        customOptionsArray.push({
          component: CustomOptionAllowedComponents.badge,
          text: code,
          colorBadge: ColorsPaletteHEX.blue100,
          themeBadge: BadgeTheme.informationalTheme,
        });
      }

      return {
        name: item.translation,
        value: item.link,
        optionsControlGroup: {
          groupName: 'menu',
          hideTitle: true,
          hideSeparator: true,
        },
        customOptions: customOptionsArray,
      };
    }) ?? []
  );
};

export const mapTabsToOptions = (
  buttonSelectOptions: ButtonContextOption[] | undefined
): Array<{
  name: string;
  value: string;
  optionsControlGroup: GroupOptions;
}> => {
  const allowedContextIds: string[] = Object.values(AllowedContextIds);

  return (
    buttonSelectOptions
      ?.filter((option: ButtonContextOption) =>
        allowedContextIds.includes(option.id)
      )
      ?.map((option: ButtonContextOption) => ({
        name: option.label,
        value: option.url,
        optionsControlGroup: {
          groupName: 'tabs',
          hideTitle: true,
          hideSeparator: false,
        },
      })) ?? []
  );
};
