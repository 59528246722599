import { EnvironmentDetails } from 'frontend-container/components/panels/maintenancePanel/types/environmentDetails';
import { MicroservicesVersions } from 'frontend-container/components/panels/maintenancePanel/types/microserviceVersion';
import { BusinessContextDataReport } from 'frontend-container/shared/businessContext/provider/report/creator';

interface Params {
  environment: EnvironmentDetails;
  microservicesVersions: MicroservicesVersions;
  contextReport?: BusinessContextDataReport;
  dateTime: string;
}

const mapBusinessContextDataReportToJsonContent = (
  contextReport: BusinessContextDataReport
): object => {
  const current = contextReport.currentGlobalContext;

  return {
    global: {
      user: current?.user.details,
      customer: current?.customer?.details,
      system: current?.system ? 'yes' : 'no',
      centralReservationOffice: current?.centralReservationOffice?.office,
      property: current?.property?.details,
      profileCenters: current?.profileCenters?.details,
    },
    cache: contextReport.cache,
  };
};

const mapMicroservicesVersionsToFrontendJsonContent = ({
  frontendLibraryVersions,
  frontendVersions,
}: MicroservicesVersions): object => {
  return {
    ...frontendVersions.reduce((aggregated, feModule) => {
      const feLibraryVersions =
        frontendLibraryVersions[feModule.name] &&
        frontendLibraryVersions[feModule.name].length > 0 &&
        frontendLibraryVersions[feModule.name].reduce(
          (aggregatedValue, next) => {
            return {
              ...aggregatedValue,
              ['Build date']: feModule.buildDate,
              [next.name]: next.libraries,
            };
          },
          {}
        );

      return {
        ...aggregated,
        [feModule.name]: {
          version: feModule.version,
          libraryVersions: {
            feLibraryVersions,
          },
        },
      };
    }, {}),
  };
};

export const mapMaintenanceDataToJsonContentString = (
  params: Params
): string => {
  const { dateTime, environment, contextReport, microservicesVersions } =
    params;

  return JSON.stringify(
    {
      Date: dateTime,
      EnvironmentDetails: environment,
      BackendMicroserviceVersions: {
        ...microservicesVersions.backendVersions.reduce(
          (aggregated, [name, version]) => {
            return {
              ...aggregated,
              [name]: version,
            };
          },
          {}
        ),
      },
      FrontendMicroservicesVersions:
        mapMicroservicesVersionsToFrontendJsonContent(microservicesVersions),
      BusinessContext: contextReport
        ? mapBusinessContextDataReportToJsonContent(contextReport)
        : {},
    },
    undefined,
    '\t'
  );
};
