import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Context,
  ContextComponent,
} from 'frontend-container/components/Menu/components/Context';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { redirectToPropertyOrNoPermissionsPage } from 'frontend-container/components/Menu/components/CroContext/service';
import {
  getProfileCentersContexts,
  isProfileCentersEnabled,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { useProfileCentersDateTime } from 'frontend-container/components/Menu/components/ProfileCentersContext/useProfileCentersDateTime';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { profileCentersPath } from 'frontend-container/components/Menu/utils/modules/profileCenters';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';
import { redirectToProperRegionIfNecessary } from 'frontend-container/utils/region/redirectToProperRegionIfNecessary';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { isEmpty } from '@ac/library-utils/dist/utils';

export const ProfileCentersContext = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<Context | undefined>(undefined);
  const { t } = useTranslation();
  const profileCentersContexts = useMemo(() => getProfileCentersContexts(), []);

  const initialize = useCallback(async (): Promise<void> => {
    const tenantId = LoginService.authData()?.tenantId;

    if (!tenantId) {
      throw new Error('Tenant ID is undefined');
    }

    setIsLoading(true);

    if (isEmpty(profileCentersContexts) || !isProfileCentersEnabled()) {
      const propertyContexts = getPropertyContexts();

      return redirectToPropertyOrNoPermissionsPage(propertyContexts.length);
    }

    const centerSelected = selectInitialContext(
      profileCentersContexts,
      SessionService.getProfileCenterId()
    );

    if (centerSelected) {
      await selectUnitInBusinessContext({
        tenantId,
        profileCenterId: centerSelected.id,
      });
    }

    setSelected(centerSelected);
    setSessionContext(centerSelected);

    setIsLoading(false);

    if (centerSelected) {
      redirectToProperRegionIfNecessary(centerSelected);
    }
  }, [profileCentersContexts]);

  const onSelect = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSelected(context);
    setSessionContext(context);

    if (tenantId) {
      history.replaceState({}, '', profileCentersPath);

      void selectUnitInBusinessContext({
        tenantId,
        profileCenterId: context.id,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', profileCentersPath);
      }
    }
  };

  useEffect(() => {
    void initialize();
  }, [initialize]);

  return isLoading ? (
    <ac-loader-covering />
  ) : (
    <ContextComponent
      selectedContext={selected}
      onSelect={onSelect}
      useDateTime={useProfileCentersDateTime}
      dateLabel={t('MENU.CONTEXT.PROFILE_CENTERS.DATE')}
      timeLabel={t('MENU.CONTEXT.PROFILE_CENTERS.TIME')}
    />
  );
};
