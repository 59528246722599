import {
  CentralReservationOffice,
  CentralReservationOfficeUserListItemDto,
  ProfileCenterDto,
  UnitAssignmentListItem,
} from '@ac/library-api';
import { IconName } from '@ac/web-components';

import { Context, ContextType } from './context';

const unitToContext = (item: UnitAssignmentListItem): Context => ({
  id: item.unitId,
  code: item.code,
  name: item.name,
  regionCode: item.regionCode,
  type: ContextType.PROPERTY,
  icon: IconName.configProperty,
});

const croToContext = (
  item: CentralReservationOffice | CentralReservationOfficeUserListItemDto
): Context => ({
  id: item.id,
  code: item.code ?? '',
  name: item.name ?? '',
  regionCode: item.regionCode ?? '',
  type: ContextType.CRO,
  icon: IconName.cro,
});

const profileCenterToContext = (item: ProfileCenterDto): Context => ({
  id: item.id,
  code: item.code,
  name: '',
  regionCode: '',
  type: ContextType.PROFILE_CENTER,
  icon: IconName.profileDetails,
});

export const ContextMapper = {
  unitToContext,
  croToContext,
  profileCenterToContext,
};
