import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  FeatureToggleName,
  propertyPermissionKeys,
  ReservationsCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const reservationPermissions = propertyPermissionKeys.reservations;

export const getFrontdeskMenu = (): MenuElement => {
  const { property } = getBusinessContextData();

  const queueRoomsFeatureToggle = property?.featureToggles.find(
    ({ featureName }) =>
      featureName === FeatureToggleName.EmberToReactRoomQueueReactEnabled
  );

  const queueRoomsLink = `${
    queueRoomsFeatureToggle?.enabled
      ? acConfig.newFrontendUrls.housekeeping
      : acConfig.newFrontendUrls.reservations
  }/queue-rooms`;

  return {
    translation: 'MENU.FRONTDESK.TITLE',
    icon: IconName.frontdesk,
    id: 'menu-frontdesk',
    items: [
      {
        link: `${acConfig.newFrontendUrls.reservations}/frontdesk`,
        code: 'FrontDeskDashboard',
        translation: 'MENU.FRONTDESK.ITEMS.DASHBOARD',
        permissionsConfiguration: {
          permissions: [reservationPermissions.dashboard.viewDashboard],
          permissionsConjunction: PermissionsConjunction.And,
        },
        keyboardShortcutWindows: 'alt+ctrl+f',
        keyboardShortcutMac: '⌥+⌘+f',
        keyCode: 70,
      },
      {
        link: `${acConfig.newFrontendUrls.reports}/house-status-dashboard`,
        code: 'HouseStatus',
        translation: 'MENU.FRONTDESK.ITEMS.HOUSE_STATUS_DASHBOARD',
        keyboardShortcutWindows: 'alt+ctrl+s',
        keyboardShortcutMac: '⌥+⌘+s',
        keyCode: 83,
      },
      {
        link: `${acConfig.newFrontendUrls.housekeeping}/room-planner`,
        code: 'RoomPlanner',
        translation: 'MENU.FRONTDESK.ITEMS.ROOM_PLANNER',
        permissionsConfiguration: {
          permissions: [reservationPermissions.dashboard.viewDashboard],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [ReservationsCustomerSettingsKeys.IsRoomPlannerActive],
        keyboardShortcutWindows: 'alt+ctrl+l',
        keyboardShortcutMac: '⌥+⌘+l',
        keyCode: 76,
      },
      {
        link: `${acConfig.newFrontendUrls.housekeeping}/rooms/assignment`,
        code: 'RoomAssignment',
        translation: 'MENU.FRONTDESK.ITEMS.ROOM_ASSIGNMENT',
        permissionsConfiguration: {
          permissions: [reservationPermissions.reservation.viewReservation],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [ReservationsCustomerSettingsKeys.RoomAssignment],
        featureToggles: [FeatureToggleName.RoomAssignmentDashboard],
        keyboardShortcutWindows: 'alt+ctrl+]',
        keyboardShortcutMac: '⌥+⌘+]',
        keyCode: 221,
      },
      {
        link: `${acConfig.newFrontendUrls.reservations}/reservations/walk-in`,
        translation: 'MENU.FRONTDESK.ITEMS.WALK_IN',
        permissionsConfiguration: {
          permissions: [
            reservationPermissions.reservation.manageReservation,
            reservationPermissions.reservation.checkInReservation,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        keyboardShortcutWindows: 'alt+ctrl+/',
        keyboardShortcutMac: '⌥+⌘+/',
        keyCode: 191,
      },
      {
        link: queueRoomsLink,
        translation: 'MENU.FRONTDESK.ITEMS.QUEUE_ROOMS',
        permissionsConfiguration: {
          permissions: [propertyPermissionKeys.queueRooms.viewDashboard],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [ReservationsCustomerSettingsKeys.queueRooms],
      },
      {
        link: `${acConfig.newFrontendUrls.individualReservations}/guest-messages`,
        translation: 'MENU.FRONTDESK.ITEMS.GUEST_MESSAGES',
        permissionsConfiguration: {
          permissions: [
            reservationPermissions.guestMessages.accessMessageCenter,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [ReservationsCustomerSettingsKeys.GuestMessage],
        keyboardShortcutWindows: 'alt+ctrl+m',
        keyboardShortcutMac: '⌥+⌘+m',
        keyCode: 77,
      },
      {
        link: `${acConfig.newFrontendUrls.individualReservations}/wake-up-calls`,
        translation: 'MENU.FRONTDESK.ITEMS.WAKE_UP_CALLS',
        permissionsConfiguration: {
          permissions: [reservationPermissions.reservation.viewReservation],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [ReservationsCustomerSettingsKeys.WakeUpCalls],
        keyboardShortcutWindows: 'alt+ctrl+w',
        keyboardShortcutMac: '⌥+⌘+w',
        keyCode: 87,
      },
    ],
  };
};
