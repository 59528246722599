import { isMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';

import { openUserDocumentation } from '@ac/react-infrastructure';
import { ButtonPattern, ButtonTheme, IconName } from '@ac/web-components';

interface Props {
  className?: string;
}

export const UserDocumentationButton = ({ className }: Props): JSX.Element => {
  return (
    <ac-button
      pattern={
        !isMenuV2Enabled() ? ButtonPattern.tertiary : ButtonPattern.menuTrigger
      }
      theme={!isMenuV2Enabled() ? ButtonTheme.light : ButtonTheme.dark}
      onClick={openUserDocumentation}
      class={className}
    >
      <ac-button-content icon={IconName.help} />
    </ac-button>
  );
};
