import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { getCroContexts } from 'frontend-container/components/Menu/components/CroContext/service';
import { getNewUrlForReservationsModuleIfNeeded } from 'frontend-container/components/Menu/components/PropertyContext/getNewUrlForReservationsModuleIfNeeded';
import { usePropertyIdentifierColor } from 'frontend-container/components/Menu/components/PropertyContext/usePropertyIdentifierColor';
import { isCashieringBillingModule } from 'frontend-container/components/Menu/utils/modules/cashiering';
import { isHousekeepingModule } from 'frontend-container/components/Menu/utils/modules/housekeeping';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { PresenterResultType } from 'frontend-container/publicApi';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import {
  getOriginForNewRegion,
  getUrlForNewRegion,
  getUrlWithContextParam,
} from 'frontend-container/utils/region/getUrlForNewRegion';
import { redirectToProperRegionIfNecessary } from 'frontend-container/utils/region/redirectToProperRegionIfNecessary';

import { SessionService } from '@ac/library-utils/dist/services';
import { getGlobalRegionCode } from '@ac/library-utils/dist/utils/multi-region';

import { Context, ContextComponent } from '../Context';
import { useBusinessDateTime } from '../Context/components/ContextDropdown/BusinessDate';

import { getNewUrlForCashieringModule } from './getNewUrlForCashieringModule';
import { getNewUrlForHousekeepingModule } from './getNewUrlForHousekeepingModule';
import {
  getPropertyContexts,
  redirectToCroOrNoPermissionsPage,
} from './service';

interface Props {
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
  color?: string;
}

export const PropertyContext = ({
  onLoadingStart,
  onLoadingEnd,
  color,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState<Context | undefined>(undefined);
  const { t } = useTranslation();

  const brandingPropertyIdentifierColor = usePropertyIdentifierColor(
    selected?.id
  );

  const enableLoading = (): void => {
    setIsLoading(true);
    onLoadingStart?.();
  };

  const disableLoading = (): void => {
    setIsLoading(false);
    onLoadingEnd?.();
  };

  const initialize = async (): Promise<void> => {
    enableLoading();

    const croContexts = getCroContexts();
    const propertyContexts = getPropertyContexts();
    const propertiesCount = propertyContexts.length;

    if (propertiesCount === 0) {
      return redirectToCroOrNoPermissionsPage(croContexts);
    }

    const context = selectInitialContext(
      propertyContexts,
      SessionService.getPropertyId()
    );

    setSessionContext(context);
    setSelected(context);
    disableLoading();

    if (context) {
      redirectToProperRegionIfNecessary(context);
    }
  };

  useEffect(() => {
    void initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = async (
    option: Context,
    discardUnsavedChanges = false
  ): Promise<void> => {
    if (selected?.id === option.id) {
      return;
    }

    if (
      !discardUnsavedChanges &&
      window.ACP?.container?.hasUnsavedChanges?.()
    ) {
      const modal = window.ACP?.container?.modals?.unsavedChanges;
      const isUnsavedChangesModalVisible = modal?.isVisible ?? false;

      if (!isUnsavedChangesModalVisible) {
        const result = await modal?.show();

        if (result?.type === PresenterResultType.Confirmed) {
          await handleSelect(option, true);
        } else {
          return;
        }
      }
    }

    const currentPropertyId = SessionService.getPropertyId();
    SessionService.setCashierNumber(undefined);
    SessionService.setPropertyCashierNumber(currentPropertyId, undefined);

    setSessionContext(option);
    setSelected(option);

    if (option?.regionCode !== selected?.regionCode) {
      const region = findRegionData(
        option?.regionCode ?? getGlobalRegionCode()
      );

      const originForNewRegion = getOriginForNewRegion(
        region?.code ?? '',
        region?.domain ?? ''
      );

      if (isHousekeepingModule() && option.id) {
        return window.location.replace(
          getUrlWithContextParam(
            getNewUrlForHousekeepingModule(
              option.id,
              window.location.pathname,
              originForNewRegion
            )
          )
        );
      }

      const newReservationsUrl = getNewUrlForReservationsModuleIfNeeded(
        window.location,
        originForNewRegion
      );
      if (newReservationsUrl) {
        return window.location.replace(
          getUrlWithContextParam(newReservationsUrl)
        );
      }

      return window.location.replace(
        getUrlForNewRegion(region?.code ?? '', region?.domain ?? '', option)
      );
    }

    if (isCashieringBillingModule()) {
      return window.location.replace(getNewUrlForCashieringModule());
    }

    if (isHousekeepingModule() && option.id) {
      return window.location.replace(
        getNewUrlForHousekeepingModule(
          option.id,
          window.location.pathname,
          window.location.origin
        )
      );
    }

    const newReservationsUrl = getNewUrlForReservationsModuleIfNeeded(
      window.location
    );
    if (newReservationsUrl) {
      return window.location.replace(newReservationsUrl);
    }

    if (window.location.search) {
      window.location.replace(window.location.pathname);
    }

    window.location.reload();
  };

  return isLoading ? (
    <ac-loader-covering />
  ) : (
    <ContextComponent
      selectedContext={selected}
      useDateTime={useBusinessDateTime}
      onSelect={handleSelect}
      color={color ?? brandingPropertyIdentifierColor}
      dateLabel={t('MENU.CONTEXT.PROPERTY.DATE')}
      timeLabel={t('MENU.CONTEXT.PROPERTY.TIME')}
    />
  );
};
