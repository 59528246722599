import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  hasPermissionToReadKey,
  isReadKeyFeatureOn,
} from 'frontend-container/components/Menu/components/Workstation/components/ReadKey/permissionsAndFeatureToggle';
import { ReadKeyModal } from 'frontend-container/components/Menu/components/Workstation/components/ReadKey/ReadKeyModal';
import { WorkstationList } from 'frontend-container/components/Menu/components/Workstation/components/WorkstationList';
import { WorkstationListHeader } from 'frontend-container/components/Menu/components/Workstation/components/WorkstationListHeader';
import { WorkstationSelectionModal } from 'frontend-container/components/Menu/components/Workstation/components/WorkstationSelection';
import { WorkstationTrigger } from 'frontend-container/components/Menu/components/Workstation/components/WorkstationTrigger';
import { useWorkstationInitialization } from 'frontend-container/components/Menu/components/Workstation/useWorkstationInitialization';
import { useWorkstations } from 'frontend-container/components/Menu/components/Workstation/useWorkstations';
import i18n from 'frontend-container/i18n';
import { ModalCallback } from 'frontend-container/publicApi/windowObject';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { AcSelectOption, AlignItems, Components } from '@ac/web-components';
import { HTMLStencilElement } from '@ac/web-components/dist/types/stencil-public-runtime';

interface ModalCallbacksObject {
  onCancel?: ModalCallback;
  onSuccess?: ModalCallback;
}

export const WorkstationMenu = (): JSX.Element => {
  const [isWorkstationSelectionModalOpen, setIsWorkstationSelectionModalOpen] =
    useState<boolean>(false);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [isReadKeyModalOpen, setIsReadKeyModalOpen] = useState(false);
  const propertyContextData = getPropertyContextData();

  const {
    loadingWorkstationDetails,
    workstation,
    setWorkstation,
    setupWorkstation,
    workstations,
    keyEncoder,
    paymentDevice,
    passportScanner,
    printer,
    eRegistrationDevice,
  } = useWorkstations();

  const wrapperRef = useRef<
    (Components.AcFlex & HTMLStencilElement) | undefined
  >();

  const createRef = (
    el: (Components.AcFlex & HTMLStencilElement) | undefined
  ): void => {
    wrapperRef.current = el;
  };

  const openList = (): void => setIsListVisible(true);

  const closeList = (): void => setIsListVisible(false);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return;
    }

    wrapper.addEventListener('mouseenter', openList);
    wrapper.addEventListener('mouseleave', closeList);

    return (): void => {
      wrapper.removeEventListener('mouseenter', openList);
      wrapper.removeEventListener('mouseleave', closeList);
    };
  }, []);

  const actions = useRef<ModalCallbacksObject>({
    onCancel: undefined,
    onSuccess: undefined,
  });

  useEffect(() => {
    if (window?.ACP?.container) {
      window.ACP.container.workstationSelectionModal =
        handleOpenWorkstationSelection;
      window.ACP.container.updateWorkstation = setupWorkstation;
    }

    return (): void => {
      if (window?.ACP?.container) {
        delete window.ACP.container.workstationSelectionModal;
        delete window.ACP.container.updateWorkstation;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWorkstation = useCallback(() => workstation, [workstation]);
  const getPassportScanner = useCallback(
    () => passportScanner,
    [passportScanner]
  );

  useWorkstationInitialization('getWorkstation', getWorkstation);
  useWorkstationInitialization('getPassportScanner', getPassportScanner);

  const executeModalCallback = async (
    isSuccess: boolean,
    data?: unknown
  ): Promise<void> => {
    if (isSuccess) {
      await actions.current.onSuccess?.(data);
    } else {
      await actions.current.onCancel?.(data);
    }
    actions.current = {
      onCancel: undefined,
      onSuccess: undefined,
    };
  };

  const toggleWorkstationSelectionModal = (): void => {
    setIsWorkstationSelectionModalOpen(!isWorkstationSelectionModalOpen);
  };

  const handleOpenWorkstationSelection = (
    onCancel?: ModalCallback,
    onSuccess?: ModalCallback
  ): void => {
    actions.current = {
      onCancel,
      onSuccess,
    };
    toggleWorkstationSelectionModal();
  };

  const handleWorkstationSelectionCancel = async (): Promise<void> => {
    toggleWorkstationSelectionModal();
    await executeModalCallback(false);
  };

  const handleWorkstationSelectionConfirm = async (
    workstationId: string
  ): Promise<void> => {
    await executeModalCallback(true, { workstationId });
    toggleWorkstationSelectionModal();
  };

  const onCloseReadKeyModalHandler = (): void => setIsReadKeyModalOpen(false);
  const onOpenReadKeyModalHandler = (): void => setIsReadKeyModalOpen(true);

  const preselectedWorkstation: AcSelectOption | undefined = useMemo(
    () => ({
      name: workstation?.descriptionMap[i18n.language] || '',
      value: workstation?.id,
    }),
    [workstation?.descriptionMap, workstation?.id]
  );

  const isUserPermittedToReadKey = hasPermissionToReadKey(
    propertyContextData?.permissions.permissionIds || []
  );

  const isReadKeyFeatureEnabled = isReadKeyFeatureOn(
    propertyContextData?.featureToggles || []
  );

  return (
    <ac-flex
      ref={createRef}
      id="workstation-wrapper"
      alignItems={AlignItems.center}
    >
      <WorkstationTrigger isWorkstationSelected={!!workstation} />

      <WorkstationList
        workstation={workstation}
        workstations={workstations}
        isVisible={isListVisible}
        setWorkstation={setWorkstation}
        onClose={closeList}
      >
        <WorkstationListHeader
          loadingWorkstationDetails={loadingWorkstationDetails}
          workstation={workstation}
          keyEncoder={keyEncoder}
          paymentDevice={paymentDevice}
          passportScanner={passportScanner}
          printer={printer}
          eRegistrationDevice={eRegistrationDevice}
          onOpenReadKeyModal={onOpenReadKeyModalHandler}
          isUserPermittedToUseReadKeyButton={isUserPermittedToReadKey}
          isReadKeyFeatureEnabled={isReadKeyFeatureEnabled}
        />
      </WorkstationList>

      {isWorkstationSelectionModalOpen && (
        <WorkstationSelectionModal
          onClose={handleWorkstationSelectionCancel}
          onConfirm={handleWorkstationSelectionConfirm}
        />
      )}

      {isReadKeyModalOpen && (
        <ReadKeyModal
          workstations={workstations}
          preselectedWorkstation={preselectedWorkstation}
          onCloseModal={onCloseReadKeyModalHandler}
        />
      )}
    </ac-flex>
  );
};
