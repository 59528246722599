import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { isMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';

import {
  AlignItems,
  Color,
  FlexDirection,
  Orientation,
  Size,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import { Context } from '../context';

import './ContextHeader.scss';

interface Props {
  selectedContext: Context | undefined;
  date: string | undefined;
  dateLabel: string;
  time: string | undefined;
  timeLabel: string;
  isInsideTooltip?: boolean;
  buttonSelectOptions?: ButtonContextOption[];
  activeTab?: string;
  setActiveTab?: (activeTab: string) => void;
}

export const ContextHeader: FC<Props> = ({
  selectedContext,
  date,
  dateLabel,
  time,
  timeLabel,
  isInsideTooltip,
  buttonSelectOptions,
  activeTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();

  const isMenuV2EnabledCached = isMenuV2Enabled();

  const { currentButtonOption } = useContextsContext();

  if (isMenuV2EnabledCached && !currentButtonOption) {
    return null;
  }

  const contextDropdownMenuv2 = (
    <ac-tabs-v2>
      {buttonSelectOptions?.map((button) => {
        const buttonId = `context-button-item-${button.url}`;

        const handleClick = (): void => {
          if (setActiveTab) {
            setActiveTab(button.id);
          }
        };

        return (
          <Fragment key={button.id}>
            <ac-tabs-item-v2
              active={activeTab === button.id}
              text={button.label}
              onClickCallback={handleClick}
              id={buttonId}
            />
          </Fragment>
        );
      })}
    </ac-tabs-v2>
  );

  const contextDropdownMenuv1 = (
    <>
      <ac-flex
        alignItems={AlignItems.center}
        class="ac-spacing-bottom-sm ac-gap-sm"
      >
        {selectedContext && (
          <ac-icon
            icon={selectedContext.icon}
            size={Size.lg}
            color={Color.black}
          />
        )}
        <ac-flex direction={FlexDirection.column}>
          {selectedContext?.code && (
            <ac-text weight={TextWeight.semibold} uppercase>
              {selectedContext.code}
            </ac-text>
          )}
          {selectedContext?.name && (
            <ac-text size={TextSize.sm}>{selectedContext.name}</ac-text>
          )}
        </ac-flex>
      </ac-flex>
      <ac-flex alignItems={AlignItems.center}>
        {selectedContext?.regionCode && (
          <ac-text-group
            label={t('MENU.CONTEXT.REGION')}
            orientation={Orientation.vertical}
            class="ac-spacing-right-sm"
          >
            <ac-text uppercase>{selectedContext.regionCode}</ac-text>
          </ac-text-group>
        )}
        {date && selectedContext && (
          <ac-text-group
            label={dateLabel}
            orientation={Orientation.vertical}
            class="ac-spacing-right-sm"
          >
            <span>{date}</span>
          </ac-text-group>
        )}
        {time && selectedContext && (
          <ac-text-group
            label={timeLabel}
            orientation={Orientation.vertical}
            class="ac-spacing-right-sm"
          >
            <span>{time}</span>
          </ac-text-group>
        )}
      </ac-flex>
    </>
  );

  return (
    <div
      className={`${isInsideTooltip ? 'context-header-in-tooltip' : ''} 
        ${
          isMenuV2EnabledCached
            ? 'container-menu-item-header-menu2'
            : 'container-menu-item-header'
        }`}
    >
      {isMenuV2EnabledCached ? contextDropdownMenuv2 : contextDropdownMenuv1}
    </div>
  );
};
