import { ButtonTrigger } from 'frontend-container/components/Menu/components/MainMenuInfotip/ButtonTrigger';
import { isMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';

import { Color, IconName } from '@ac/web-components';

interface Props {
  isWorkstationSelected: boolean;
}

export const WorkstationTrigger = ({
  isWorkstationSelected,
}: Props): JSX.Element => (
  <>
    <ButtonTrigger
      triggerId="workstation-trigger-button"
      buttonIcon={IconName.workstation}
      statusInactiveIcon={IconName.cancel}
      inactiveColor={Color.gray}
      active={isWorkstationSelected}
      additionalClass={!isMenuV2Enabled() ? 'ac-margin-inline-end-sm' : ''}
    />
  </>
);
