import { EnvironmentDetails } from 'frontend-container/components/panels/maintenancePanel/types/environmentDetails';

interface DetailsItem {
  translationPath: string;
  value: string;
}

const getItem = (key: string, value: string | undefined): DetailsItem => ({
  translationPath: `MAINTENANCE_PANEL.ENVIRONMENT.${key}`,
  value: value ?? '-',
});

export const mapEnvironmentDetailsToDisplayValues = (
  environmentDetails: EnvironmentDetails
): DetailsItem[] => {
  return [
    getItem('BROWSER_DETAILS', environmentDetails.browserDetails),
    getItem('SCREEN_SIZE', environmentDetails.screenSize),
    getItem(
      'COOKIE_ENABLED',
      environmentDetails.isCookieEnabled ? 'Enabled' : 'Disabled'
    ),
    getItem(
      'WEB_SOCKET_SUPPORT',
      environmentDetails.isWebSocketSupported ? 'Available' : 'Not available'
    ),
    getItem('OS_DETAILS', environmentDetails.OSDetails),
    getItem('DEVICE_NAME', environmentDetails.mobileDeviceName),
  ];
};
