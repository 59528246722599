import { FC, ReactNode } from 'react';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';

import {
  AcSelectOption,
  Placement,
  SelectableListSlot,
  TargetValueObject,
} from '@ac/web-components';

import {
  handleOnChange,
  isContextList,
} from '../../../utils/listOnChangeHandler';
import { Context } from '../context';
import {
  mapContextsToOptions,
  mapMenuItemsToOptions,
  mapTabsToOptions,
} from '../mapListsToOptions';

interface Props {
  list: Context[] | ButtonContextOption | undefined;
  selectedContext: Context | undefined;
  isVisible?: boolean;
  children: ReactNode;
  onSelect: (
    selected: AcSelectOption[] | Context | object[] | undefined
  ) => void;
  onClose: () => void;
  buttonSelectOptions?: ButtonContextOption[];
  searchableListLength?: number;
}

export const GenericList: FC<Props> = ({
  list,
  isVisible,
  selectedContext,
  children,
  onSelect,
  onClose,
  buttonSelectOptions,
  searchableListLength = 7,
}) => {
  const onClick = (context: Context | undefined): void => {
    onSelect(context);
  };

  const contextType = isContextList(list);

  let listLength = 0;
  let onChange;
  if (contextType) {
    listLength = list.length;
    onChange = handleOnChange(list, onClick);
  } else {
    listLength = list?.subMenuElements?.length ?? 0;
  }

  const searchable = listLength > searchableListLength;

  const contextListProps = {
    searchable,
    optionSelectedField: 'value',
    value: [selectedContext?.id || ''],
    onChangeCallback: onChange,
  };

  const nonContextListProps = {
    searchable: false,
    optionSelectedField: 'id',
    value: [`${Math.random()}`], // new value will disable selected item
    selectedItemsSection: false,
    onChangeCallback: onSelect,
  };

  const currentListProps = contextType ? contextListProps : nonContextListProps;

  return (
    <>
      <div id="context-list-wrapper">
        <ac-selectable-list
          minOptionsWidth={{ listContainer: 400, optionsWrapper: 400 }}
          attachTo="#context-list-wrapper"
          isBoundaryContainerApplied={false}
          autoFilter
          isVisible={isVisible}
          placement={Placement.bottomEnd}
          target="#context-dropdown-button"
          targetValue={TargetValueObject.mainMenu}
          optionNameField="name"
          optionValueField="value"
          dropdownClass="selectable-list-dropdown-wrapper"
          onCloseCallback={onClose}
          {...currentListProps}
          optionsArray={
            contextType
              ? mapContextsToOptions(list)
              : [
                  ...mapMenuItemsToOptions(list, selectedContext),
                  ...mapTabsToOptions(buttonSelectOptions),
                ]
          }
        >
          <div slot={SelectableListSlot.listHeader} className="list-header">
            {children}
          </div>
        </ac-selectable-list>
      </div>
    </>
  );
};
