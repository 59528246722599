import { Fragment, useState } from 'react';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { compareRouteLinkWithPathname } from 'frontend-container/components/Menu/components/DocumentTitle/service';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { MenuElementItem } from 'frontend-container/components/Menu/types';
import { CONTAINER_ROOT_SELECTOR } from 'frontend-container/shared/constants';

import {
  ButtonPattern,
  ButtonTheme,
  DividerDirection,
  FlexGap,
  GenericButton,
  Placement,
  TargetValueObject,
} from '@ac/web-components';

interface Props {
  showDivider?: boolean;
  allAccessConfiguration?: AllAccessConfiguration;
  buttonSelectOptions?: ButtonContextOption[];
  currentButtonOption?: ButtonContextOption;
  buttonProps?: GenericButton;
  hideIcon?: boolean;
}

const contextSelectButtonId = 'context-select-button';

export const ContextSelectButton = ({
  showDivider = true,
  buttonSelectOptions,
  currentButtonOption,
  buttonProps,
  hideIcon,
}: Props): JSX.Element | null => {
  const [isElementActive, setIsElementActive] = useState(false);

  const [activeItem, setActiveItem] = useState<string>();

  const resetSelection = (): void => {
    setIsElementActive(false);
    setActiveItem(undefined);
  };

  const handleOnMouseOver = (): void => setIsElementActive(true);
  const handleOnMouseOverItem = (label: string): void => setActiveItem(label);
  const handleOnSubItemClick = async (
    link: string,
    menuElementItems?: MenuElementItem[] | undefined,
    onBeforeRedirectCallback?: () => void
  ): Promise<void> => {
    return handleUnsavedChangesRedirect(
      false,
      link,
      async () =>
        await handleMenuItemRedirect(
          link,
          menuElementItems,
          onBeforeRedirectCallback,
          true
        )
    );
  };

  const getIsItemActive = (menuElementItem: MenuElementItem): boolean => {
    const isConfigurationItem = menuElementItem.link.includes('configuration');

    return compareRouteLinkWithPathname(
      {
        // prefer first alias as it's more accurate for configuration items
        link: isConfigurationItem
          ? menuElementItem.aliases?.[0] ?? menuElementItem.link
          : menuElementItem.link,
        routeTranslation: '',
        moduleTranslation: '',
      },
      window.location.pathname
    );
  };

  const isContextButtonVisible =
    buttonSelectOptions && buttonSelectOptions.length < 2;

  if (!currentButtonOption || isContextButtonVisible) {
    return null;
  }

  return (
    <>
      <ac-button
        pattern={ButtonPattern.tertiary}
        theme={ButtonTheme.light}
        {...buttonProps}
        onMouseEnter={handleOnMouseOver}
        onMouseLeave={resetSelection}
        id={contextSelectButtonId}
      >
        <ac-button-content
          text={currentButtonOption.label}
          icon={hideIcon ? undefined : currentButtonOption.icon}
        />
      </ac-button>
      <ac-position-wrapper
        dynamicClass="menu-subitems-wrapper layout-direction"
        attachTo={CONTAINER_ROOT_SELECTOR}
        isBoundaryContainerApplied={false}
        contentHidden={!isElementActive}
        target={`#${contextSelectButtonId}`}
        targetValue={TargetValueObject.mainMenu}
        placement={Placement.bottomStart}
        onMouseOver={handleOnMouseOver}
        onMouseLeave={resetSelection}
      >
        <div className="menu-subitems-container menu-subitems-container-dynamic">
          {buttonSelectOptions?.map((button) => {
            const isButtonWithSubItems = Boolean(
              button.subMenuElements?.length
            );

            const buttonId = `context-button-item-${button.id}`;

            return (
              <Fragment key={button.id}>
                <SubItem
                  item={{ link: button.url, translation: button.label }}
                  active={currentButtonOption.id === button.id}
                  onClick={button.onClick}
                  id={buttonId}
                  onMouseOver={(): void => handleOnMouseOverItem(button.label)}
                  showActionArrow={isButtonWithSubItems}
                  spacing={FlexGap.sm}
                />
                {isButtonWithSubItems && (
                  <ac-position-wrapper
                    dynamicClass="menu-subitems-wrapper layout-direction"
                    attachTo={CONTAINER_ROOT_SELECTOR}
                    isBoundaryContainerApplied={false}
                    contentHidden={activeItem !== button.label}
                    target={`#${buttonId}`}
                    targetValue={TargetValueObject.mainMenu}
                    placement={Placement.rightStart}
                    onMouseOver={handleOnMouseOver}
                    onMouseLeave={resetSelection}
                    offset={{
                      distanceFromTarget: -4,
                      shiftFromTheMiddle: 0,
                    }}
                  >
                    <div className="menu-subitems-container menu-subitems-container-dynamic">
                      {button.subMenuElements?.map((item) => (
                        <SubItem
                          item={item}
                          key={item.link}
                          active={getIsItemActive(item)}
                          onClick={handleOnSubItemClick}
                        />
                      ))}
                    </div>
                  </ac-position-wrapper>
                )}
              </Fragment>
            );
          })}
        </div>
      </ac-position-wrapper>
      {showDivider && (
        <ac-divider direction={DividerDirection.vertical} class="bg-gray2" />
      )}
    </>
  );
};
