import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsButton } from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsButton';
import { BreadcrumbsMobileView } from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsMobileView';
import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { ContextSelectButton } from 'frontend-container/components/Menu/components/ContextSelectButton/ContextSelectButton';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { isCroContextVisible } from 'frontend-container/components/Menu/components/CroContext';
import {
  getCroContextById,
  getCroItems,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import {
  getProfileCentersContexts,
  getProfileCentersItems,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';
import {
  getPropertyContexts,
  getPropertyUnits,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getUserUnitsDetails } from 'frontend-container/components/Prestep/getUserUnitsDetails';

import {
  ResponsiveViewport,
  useViewportContext,
  ViewBreakpoint,
} from '@ac/react-infrastructure';
import { ButtonPattern, ButtonSize } from '@ac/web-components';

interface Props {
  buttonSelectOptions?: ButtonContextOption[];
  currentButtonOption?: ButtonContextOption;
  pageTitle?: string;
  currentModule?: MenuElement;
}

export const Breadcrumbs = ({
  buttonSelectOptions,
  currentButtonOption,
  currentModule,
  pageTitle,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const { propertyId, centralReservationOfficeId, profileCenterId } =
    useSessionContextTypeIds();

  const details = useMemo(() => {
    const croUnits = getCroItems();
    const properties = getPropertyUnits();
    const profileCenters = getProfileCentersItems();

    const userUnitsDetails = getUserUnitsDetails(
      properties,
      croUnits,
      profileCenters
    );

    return userUnitsDetails;
  }, []);

  const unit = useMemo((): Context | undefined => {
    if (isPropertyContextVisible() && propertyId) {
      const propertyContexts = getPropertyContexts();

      return propertyContexts.find(({ id }) => id === propertyId);
    }

    if (isCroContextVisible() && centralReservationOfficeId) {
      return getCroContextById(centralReservationOfficeId);
    }

    if (isProfileCentersContextVisible() && profileCenterId) {
      return getProfileCentersContexts().find(
        ({ id }) => id === profileCenterId
      );
    }

    return;
  }, [centralReservationOfficeId, profileCenterId, propertyId]);

  const {
    state: { responsiveBreakpoint },
  } = useViewportContext();

  const isMobileMenuHidden = responsiveBreakpoint > ViewBreakpoint.MD;

  const menuItemTranslation = t(currentModule?.translation ?? '');

  const isContextButtonVisible =
    buttonSelectOptions && buttonSelectOptions.length >= 2;

  const isUnitBreadcrumbVisible = useMemo((): boolean => {
    switch (unit?.type) {
      case ContextType.PROPERTY: {
        return !details.isSinglePropertyUser;
      }
      case ContextType.CRO: {
        return !details.isSingleCroUser;
      }
      case ContextType.PROFILE_CENTER: {
        return !details.isSingleProfileCenterUser;
      }

      default:
        return false;
    }
  }, [
    unit?.type,
    details.isSingleCroUser,
    details.isSingleProfileCenterUser,
    details.isSinglePropertyUser,
  ]);

  return (
    <ResponsiveViewport>
      <ContextSelectButton
        buttonSelectOptions={buttonSelectOptions}
        currentButtonOption={currentButtonOption}
        showDivider={false}
        buttonProps={{
          pattern: ButtonPattern.menuTrigger,
          size: ButtonSize.auto,
          theme: undefined,
          class: 'breadcrumbs-button',
        }}
        hideIcon
      />

      {!isMobileMenuHidden && (
        <>
          <BreadcrumbsMobileView
            isContextButtonVisible={isContextButtonVisible}
            isUnitBreadcrumbVisible={isUnitBreadcrumbVisible}
            menuItemName={menuItemTranslation}
            pageTitle={pageTitle}
            unitCode={unit?.code}
          />
        </>
      )}

      {isMobileMenuHidden && (
        <>
          {isUnitBreadcrumbVisible && (
            <BreadcrumbsButton
              content={unit?.code}
              showIcon={isContextButtonVisible}
            />
          )}
          <BreadcrumbsButton
            content={menuItemTranslation}
            showIcon={isContextButtonVisible || isUnitBreadcrumbVisible}
          />
          <BreadcrumbsButton content={pageTitle} />
        </>
      )}
    </ResponsiveViewport>
  );
};
