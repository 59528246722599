import { useMemo, useState } from 'react';
import { isCroEnabled } from 'frontend-container/components/Menu/components/CroContext/service';
import { isProfileCentersEnabled } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { userPreferencesService } from 'frontend-container/components/Menu/components/User/UserPreferences/service';
import { getResourceValue } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/resource';
import { usePreferencesFormData } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/useFormData';
import { UserContact } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/UserContact';
import { UserPreferencesFormSection } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/UserPreferencesFormSection';
import { useFormValidation } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/validation/useFormValidation';
import { getInitialValues } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/values/getInitialValues';
import { useFormValues } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/values/useFormValues';
import { t } from 'frontend-container/i18n';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { useTranslationWithEnglishInParentheses } from 'frontend-container/utils/hooks/useTranslationWithEnglishParentheses';

import {
  FeatureToggleName,
  RequestStatus,
  UserPreferencesDetails,
} from '@ac/library-api';
import {
  AcSelectOption,
  DividerSpacing,
  TargetValueObject,
  TextColor,
  TextSize,
} from '@ac/web-components';

interface Props {
  onClose: () => void;
}

export const UserPreferencesModal = ({ onClose }: Props): JSX.Element => {
  const [initialValues] = useState(() => getInitialValues());
  const [formValues, setFieldValue] = useFormValues(initialValues);
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>(
    RequestStatus.Approved
  );

  const businessContextData = getBusinessContextData();

  const featureToggles = businessContextData?.customer?.featureToggles ?? [];
  const newMenuFeatureToggle = featureToggles.find(
    ({ featureName }) => featureName === FeatureToggleName.UseNewMenu
  );

  const {
    workspaces,
    allowedCroLandingPages,
    allowedLandingPages,
    centralReservationOffices,
    languages,
    properties,
    profileCenters,
    allowedProfileCenterLandingPages,
  } = usePreferencesFormData({
    croId: formValues.defaultCentralReservationOfficeId,
    propertyId: formValues.defaultPropertyId,
    profileCenterId: formValues.defaultProfileCenterId,
    defaultCentralReservationOfficeLandingScreen:
      initialValues.defaultCentralReservationOfficeLandingScreen,
    defaultLandingScreen: initialValues.defaultLandingScreen,
    defaultProfileCenterLandingScreen:
      initialValues.defaultProfileCenterLandingScreen,
  });

  const notAllowedValues = useMemo<Partial<UserPreferencesDetails>>(
    () => ({
      defaultLandingScreen: getResourceValue(allowedLandingPages, []).find(
        (page) => page.isDisabled
      )?.value,
      defaultCentralReservationOfficeLandingScreen: getResourceValue(
        allowedCroLandingPages,
        []
      ).find((page) => page.isDisabled)?.value,
    }),
    [allowedCroLandingPages, allowedLandingPages]
  );

  const { formValidationStatuses, hasAnyError } = useFormValidation({
    notAllowedValues,
    formValues,
  });

  const translateWithEnglishParentheses =
    useTranslationWithEnglishInParentheses();

  const onSubmit = async (): Promise<void> => {
    if (hasAnyError) {
      return;
    }
    setSubmitRequestStatus(RequestStatus.Pending);

    const hasSucceed = await userPreferencesService.saveUserPreferences(
      formValues
    );

    setSubmitRequestStatus(
      hasSucceed ? RequestStatus.Approved : RequestStatus.Rejected
    );

    if (!hasSucceed) {
      return;
    }

    if (initialValues.defaultLanguage !== formValues.defaultLanguage) {
      window.location.reload();
    } else {
      onClose();
    }
  };

  const croCount = getResourceValue(centralReservationOffices, []).length;
  const profileCentersCount = getResourceValue(profileCenters, []).length;
  const isUserCroEnabled = isCroEnabled();
  const isUserWithoutCroUnits = croCount === 0;
  const isMultiCroUser = croCount > 1;

  const isUserWithoutProfileCenters = profileCentersCount === 0;
  const isMultiProfileCenterUser = profileCentersCount > 1;

  const propertyCount = getResourceValue(properties, []).length;
  const isUserWithoutProperties = propertyCount === 0;
  const isMultiPropertyUser = propertyCount > 1;

  const isSaving = submitRequestStatus === RequestStatus.Pending;
  const isFormRejected = submitRequestStatus === RequestStatus.Rejected;
  const isWorkspaceSelectVisible =
    [
      isUserWithoutProperties,
      isUserWithoutCroUnits,
      isUserWithoutProfileCenters,
    ].filter(Boolean).length < 2;

  return (
    <ac-modal
      modalTitle={translateWithEnglishParentheses(
        'MENU.LOGGED_USER.USER_PREFERENCES.TITLE'
      )}
      confirmButton={{
        content: {
          text: t('COMMON.BUTTONS.CONFIRM'),
        },
        onClick: onSubmit,
        disabled: isSaving || hasAnyError,
      }}
      cancelButton={{
        content: {
          text: t('COMMON.BUTTONS.CANCEL'),
        },
        onClick: onClose,
      }}
    >
      <div>
        <UserContact />
        <ac-divider spacing={DividerSpacing.none} />
        {isFormRejected && (
          <ac-text color={TextColor.alert} size={TextSize.lg}>
            {t('MENU.LOGGED_USER.USER_PREFERENCES.ERROR')}
          </ac-text>
        )}
        <ac-flex wrapped>
          <UserPreferencesFormSection
            hide={!isWorkspaceSelectVisible}
            hideDivider
          >
            <ac-select
              id="default-workspace"
              label={t('MENU.LOGGED_USER.USER_PREFERENCES.WORKSPACE.LABEL')}
              value={formValues.defaultWorkspace}
              optionsArray={getResourceValue(workspaces, [])}
              optionNameField="name"
              optionValueField="value"
              loading={workspaces.status === 'pending'}
              hasLoadingError={workspaces.status === 'rejected'}
              onChangeCallback={(
                selectedOption?: AcSelectOption<string>
              ): void => {
                setFieldValue(['defaultWorkspace', selectedOption?.value]);
              }}
              allowClear
              onClearCallback={(): void =>
                setFieldValue(['defaultWorkspace', undefined])
              }
              targetValue={TargetValueObject.modal}
            />
          </UserPreferencesFormSection>
          <UserPreferencesFormSection hideDivider>
            <ac-select
              id="default-language"
              label={translateWithEnglishParentheses(
                'MENU.LOGGED_USER.USER_PREFERENCES.LANGUAGE.LABEL'
              )}
              value={formValues.defaultLanguage}
              optionsArray={getResourceValue(languages, [])}
              optionNameField="name"
              optionValueField="value"
              loading={languages.status === 'pending'}
              hasLoadingError={languages.status === 'rejected'}
              onChangeCallback={(
                selectedOption?: AcSelectOption<string>
              ): void => {
                setFieldValue(['defaultLanguage', selectedOption?.value]);
              }}
              allowClear
              onClearCallback={(): void =>
                setFieldValue(['defaultLanguage', undefined])
              }
              targetValue={TargetValueObject.modal}
            />
          </UserPreferencesFormSection>
          <UserPreferencesFormSection
            hideDivider
            hide={!newMenuFeatureToggle?.enabled}
          >
            <ac-switch
              id="display-new-menu"
              label={t('MENU.LOGGED_USER.USER_PREFERENCES.NEW_MENU.LABEL')}
              onChangeCallback={(value): void =>
                setFieldValue(['displayNewMenu', value])
              }
              checked={formValues.displayNewMenu}
            ></ac-switch>
          </UserPreferencesFormSection>
          <UserPreferencesFormSection
            sectionTitle={t(
              'MENU.LOGGED_USER.USER_PREFERENCES.SECTIONS.PROFILE_CENTER'
            )}
            hide={!isProfileCentersEnabled() || isUserWithoutProfileCenters}
          >
            {isMultiProfileCenterUser && (
              <ac-select
                id="default-profile-center"
                label={t(
                  'MENU.LOGGED_USER.USER_PREFERENCES.DEFAULT_PROFILE_CENTER.LABEL'
                )}
                value={formValues.defaultProfileCenterId}
                optionsArray={getResourceValue(profileCenters, [])}
                optionNameField="name"
                optionValueField="value"
                loading={profileCenters.status === 'pending'}
                hasLoadingError={profileCenters.status === 'rejected'}
                onChangeCallback={(
                  selectedOption?: AcSelectOption<string>
                ): void => {
                  setFieldValue([
                    'defaultProfileCenterId',
                    selectedOption?.value,
                  ]);
                }}
                allowClear
                onClearCallback={(): void =>
                  setFieldValue(['defaultProfileCenterId', undefined])
                }
                targetValue={TargetValueObject.modal}
              />
            )}
            <ac-select
              id="default-profile-center-landing-page"
              label={t(
                'MENU.LOGGED_USER.USER_PREFERENCES.PROFILE_CENTER_STARTING_PAGE.LABEL'
              )}
              value={formValues.defaultProfileCenterLandingScreen}
              optionsArray={getResourceValue(
                allowedProfileCenterLandingPages,
                []
              )}
              optionNameField="name"
              optionValueField="value"
              loading={allowedProfileCenterLandingPages.status === 'pending'}
              hasLoadingError={
                allowedProfileCenterLandingPages.status === 'rejected'
              }
              onChangeCallback={(
                selectedOption?: AcSelectOption<string>
              ): void => {
                setFieldValue([
                  'defaultProfileCenterLandingScreen',
                  selectedOption?.value,
                ]);
              }}
              allowClear
              onClearCallback={(): void =>
                setFieldValue(['defaultProfileCenterLandingScreen', undefined])
              }
              targetValue={TargetValueObject.modal}
              disabled={formValues.defaultProfileCenterId === undefined}
            />
          </UserPreferencesFormSection>
          <UserPreferencesFormSection
            sectionTitle={t('MENU.LOGGED_USER.USER_PREFERENCES.SECTIONS.CRO')}
            hide={!isUserCroEnabled || isUserWithoutCroUnits}
          >
            {isMultiCroUser && (
              <ac-select
                id="default-office-id"
                label={t('MENU.LOGGED_USER.USER_PREFERENCES.DEFAULT_CRO.LABEL')}
                value={formValues.defaultCentralReservationOfficeId}
                optionsArray={getResourceValue(centralReservationOffices, [])}
                optionNameField="name"
                optionValueField="value"
                onChangeCallback={(
                  selectedOption?: AcSelectOption<string>
                ): void => {
                  setFieldValue([
                    'defaultCentralReservationOfficeId',
                    selectedOption?.value,
                  ]);
                }}
                allowClear
                onClearCallback={(): void =>
                  setFieldValue([
                    'defaultCentralReservationOfficeId',
                    undefined,
                  ])
                }
                targetValue={TargetValueObject.modal}
              />
            )}
            {!isUserWithoutCroUnits && (
              <ac-select
                id="default-cro-page"
                label={t(
                  'MENU.LOGGED_USER.USER_PREFERENCES.CRO_STARTING_PAGE.LABEL'
                )}
                value={formValues.defaultCentralReservationOfficeLandingScreen}
                disabled={
                  formValues.defaultCentralReservationOfficeId === undefined
                }
                optionsArray={getResourceValue(allowedCroLandingPages, [])}
                optionNameField="name"
                optionValueField="value"
                loading={allowedCroLandingPages.status === 'pending'}
                hasLoadingError={allowedCroLandingPages.status === 'rejected'}
                validationStatus={
                  formValidationStatuses.defaultCentralReservationOfficeLandingScreen
                }
                onChangeCallback={(
                  selectedOption?: AcSelectOption<string>
                ): void => {
                  setFieldValue([
                    'defaultCentralReservationOfficeLandingScreen',
                    selectedOption?.value,
                  ]);
                }}
                allowClear
                onClearCallback={(): void =>
                  setFieldValue([
                    'defaultCentralReservationOfficeLandingScreen',
                    undefined,
                  ])
                }
                targetValue={TargetValueObject.modal}
              />
            )}
          </UserPreferencesFormSection>
          <UserPreferencesFormSection
            sectionTitle={t(
              'MENU.LOGGED_USER.USER_PREFERENCES.SECTIONS.PROPERTY'
            )}
            hide={isUserWithoutProperties}
          >
            {isMultiPropertyUser && (
              <ac-select
                id="default-property"
                label={t(
                  'MENU.LOGGED_USER.USER_PREFERENCES.DEFAULT_PROPERTY.LABEL'
                )}
                value={formValues.defaultPropertyId}
                optionsArray={getResourceValue(properties, [])}
                optionNameField="name"
                optionValueField="value"
                onChangeCallback={(
                  selectedOption?: AcSelectOption<string>
                ): void => {
                  setFieldValue(['defaultPropertyId', selectedOption?.value]);
                }}
                allowClear
                onClearCallback={(): void =>
                  setFieldValue(['defaultPropertyId', undefined])
                }
                targetValue={TargetValueObject.modal}
              />
            )}
            <ac-select
              id="default-starting-page"
              label={t('MENU.LOGGED_USER.USER_PREFERENCES.STARTING_PAGE.LABEL')}
              value={formValues.defaultLandingScreen}
              optionsArray={getResourceValue(allowedLandingPages, [])}
              optionNameField="name"
              optionValueField="value"
              disabled={formValues.defaultPropertyId === undefined}
              loading={allowedLandingPages.status === 'pending'}
              hasLoadingError={allowedLandingPages.status === 'rejected'}
              validationStatus={formValidationStatuses.defaultLandingScreen}
              onChangeCallback={(
                selectedOption?: AcSelectOption<string>
              ): void => {
                setFieldValue(['defaultLandingScreen', selectedOption?.value]);
              }}
              allowClear
              onClearCallback={(): void =>
                setFieldValue(['defaultLandingScreen', undefined])
              }
              targetValue={TargetValueObject.modal}
            />
          </UserPreferencesFormSection>
        </ac-flex>
      </div>
    </ac-modal>
  );
};
